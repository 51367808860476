import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

//////////////////////////////////////////////////////////////////////////////
// Selectors
const copyrightYear = new Date().getFullYear();
const copyrightYearSpan = document.querySelector('.year');
const contactForm = document.querySelector('.contactform');
const toTopButton = document.getElementById('myBtn');
const navbar = document.querySelector('nav');
const header = document.querySelector('header');
const sectionWork = document.querySelector('#section-work');
const sectionAbout = document.querySelector('#section-about');
const sectionContact = document.querySelector('#section-contact');

///////////////////////////////////////////////////////////////////////////////
// Smooth Scroll

const smoothScrollToElement = targetElement => {
  targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const scrollToPosition = e => {
  const targetText = e.target.innerText;
  const parentClass = e.target.parentElement.classList.value;

  if (parentClass === 'btn--scroll-to' || targetText === 'DISCOVER') {
    e.preventDefault();
    let targetSection;

    switch (targetText) {
      case 'Portfolio':
        targetSection = sectionWork;
        break;
      case 'About':
        targetSection = sectionAbout;
        break;
      case 'Contact':
        targetSection = sectionContact;
        break;
      case 'DISCOVER':
        targetSection = sectionWork;
        break;
    }

    if (targetSection) {
      smoothScrollToElement(targetSection);
    }
  }
};

document.addEventListener('click', scrollToPosition);

///////////////////////////////////////////////////////////////////////////////
// Copyright Year

const renderCopyrightYearSpan = function () {
  copyrightYearSpan.innerHTML = `${copyrightYear}`;
};
renderCopyrightYearSpan();

//////////////////////////////////////////////////////////////////////////////
// Form Input Focus

const inputFocused = function (e) {
  e.target.previousElementSibling.classList.add('focused');
};

const inputBlurred = function (e) {
  if (!e.target.value)
    e.target.previousElementSibling.classList.remove('focused');
};
contactForm.addEventListener('focus', inputFocused, true);
contactForm.addEventListener('blur', inputBlurred, true);

////////////////////////////////////////////////////////////////////////////////
// Button to top

const renderToTopButton = function (entries) {
  const [entry] = entries;
  if (entry.isIntersecting) toTopButton.style.display = 'none';
  if (!entry.isIntersecting) toTopButton.style.display = 'block';
};

const navbarObserver = new IntersectionObserver(renderToTopButton, {
  root: null,
  threshold: 0,
});
navbarObserver.observe(navbar);

function toTop() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
toTopButton.addEventListener('click', toTop);

//////////////////////////////////////////////////////////////////////////////////
// Custom GSAP Code

// Heading Triggers

if (sectionAbout) {
  gsap.from('.trigger-1', {
    scrollTrigger: {
      trigger: '.trigger-1',
      start: 'top 100%',
      toggleActions: 'play',
      // markers: true,
    },
    duration: 1.5,
    opacity: 0,
    ease: 'power2.out',
  });

  gsap.from('.trigger-2', {
    scrollTrigger: {
      trigger: '.trigger-2',
      start: 'top 100%',
      toggleActions: 'play',
      // markers: true,
    },
    duration: 1.5,
    opacity: 0,
    ease: 'power2.out',
  });

  gsap.from('.trigger-3', {
    scrollTrigger: {
      trigger: '.trigger-3',
      start: 'top 100%',
      toggleActions: 'play',
      // markers: true,
    },
    duration: 1.5,
    opacity: 0,
    ease: 'power2.out',
  });

  // work items

  gsap.set('.staggers-1', { opacity: 0 });

  gsap.to('.staggers-1', {
    scrollTrigger: {
      trigger: '.grid',
      start: 'top 80%',
      end: 'bottom 80%',
      toggleActions: 'play',
      once: true,
      // markers: true,
    },
    stagger: {
      each: 0.3,
      onComplete: () => {
        ScrollTrigger.refresh();
      },
    },
    duration: 3,
    opacity: 1,
    ease: 'power2.out',
  });

  // paragraph text

  gsap.from('.staggers-2', {
    scrollTrigger: {
      trigger: '#section-about-container',
      start: 'top 80%',
      end: 'bottom 0%',
      toggleActions: 'play',
      // markers: true,
    },
    stagger: {
      each: 0.3,
    },
    y: 0,
    duration: 2.0,
    opacity: 0,
    ease: 'power3.out',
  });
}

// Contact section header

gsap.from('.trigger-4', {
  scrollTrigger: {
    trigger: '.section-contact-container',
    start: 'top 100%',
    toggleActions: 'play',
    // markers: true,
  },
  duration: 1.5,
  opacity: 0,
  ease: 'power2.out',
});
